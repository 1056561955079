'use strict';
import _ from 'lodash';
import COUNT_UNREADS from "@/graphql/me/notificationCountUnreads.graphql";
import DELETE from "@/graphql/me/deleteNotification.graphql";
import Vue from "vue";
const notifications = {
    namespaced: true,
    state: {
        notifications: [],
        counts: []
    },
    getters: {
        notifications(state) {
            return state.notifications;
        },

        counts(state) {
            return state.counts;
        },

        total(state) {
            return _.sum(_.map(state.counts, x => x.count));
        }
    },
    mutations: {
        ADD_NOTIFICATION(state, notification) {
            return state.notifications.unshift(notification);
        },

        SET_NOTIFICATIONS(state, notifications) {
            return state.notifications = notifications;
        },
        
        CLEAR_NOTIFICATIONS(state) {
            return state.notifications = [];
        },

        DELETE_NOTIFICATION(state, _id) {
            let index = _.findIndex(state.notifications, x => x._id === _id);
            if (index !== -1) {
                state.notifications.splice(index, 1);
            }
        },

        SET_COUNT(state, { type, count, inc = false }) {
            let index = _.findIndex(state.counts, x => x.type === type);
            if (index === -1) {
                state.counts.push({ type, count });
                return;
            }

            if (inc === true) {
                let val = {
                    type: type,
                    count: state.counts[index].count + count
                };

                Vue.set(state.counts, index, val);
                return;
            }

            Vue.set(state.counts, index, { type, count });
        },
    },

    actions: {
        async COUNT_UNREADS({ commit }, { vm = null }) {
            if (vm !== null) {
                let sdata = await vm.$apollo.query(COUNT_UNREADS, { type: 'SELLER' });
                let bdata = await vm.$apollo.query(COUNT_UNREADS, { type: 'BUYER' });
                let gdata = await vm.$apollo.query(COUNT_UNREADS, { type: 'GENERAL' });

                commit('SET_COUNT', { type: 'USERS', count: sdata("**.countUnreads") + bdata("**.countUnreads") });
                commit('SET_COUNT', { type: 'GENERAL', count: gdata("**.countUnreads") });
            }
        },
        
        async DELETE({ commit }, { vm = null, _id }) {
            if (vm !== null) {
                await vm.$apollo.mutate(DELETE, { _id });
                commit('DELETE_NOTIFICATION', _id);
            }
        },
    }

};
export default notifications;