import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import Vuex from "vuex";
import "./plugins/bootstrap-vue";
import "./plugins/validate";
import "./plugins/multi-select";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import mixin from "./mixin/mixin";
import "./plugins/fontawesome";
import "./plugins/dropzone";
import "./plugins/v-currency";
import Graphql from "./plugins/Graphql";
import "./filters/errorHandler";
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Vue2Editor from "vue2-editor";
import _ from 'lodash';
import moment from "moment"
import momentTz from "moment-timezone"
// import AudioRecorder from 'vue-audio-recorder'
import SweetModal from 'sweet-modal-vue/src/plugin.js'
// import VueTimeline from "@growthbunker/vuetimeline";
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import Nl2br from 'vue-nl2br'
import validator from './validators/item';

import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox);


Vue.component('nl2br', Nl2br);
Vue.use(BootstrapVueIcons)

Vue.use(Donut);
Vue.use(VueSplide);
Vue.use(Vue2Editor);
Vue.mixin(mixin);
// Vue.use(AudioRecorder);
Vue.use(SweetModal);
// Vue.use(VueTimeline);

import io from "socket.io-client"
let socket = io(
    process.env.VUE_APP_API_DOMAIN + '/api',
    {
        forceNew: true,
        secure: true,
        rejectUnauthorized: false,
        path: '/io',
        autoConnect: true,
        transports: ['websocket'],
        auth: cb => cb({ 'X-TOKEN': localStorage.getItem('conToken') }),
        query: {
            "X-TIMEZONE": momentTz.tz.guess()
        }
    }
);
//-------------------------------------------------------------------
socket.refresh = function () {
    this.disconnect();
    this.connect();
}

Vue.prototype.$_ = _;
Vue.prototype.$io = socket;
Vue.prototype.$moment = moment;

import industryIcon from "@/components/app/industryIcon.vue";
import Loading from "./components/global/loading";
Vue.component('loading', Loading)
Vue.component('industryIcon', industryIcon)

// Directives
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside);


// virtual scroller
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
Vue.use(VueVirtualScroller);

// Mapbox
import VueMapbox from "@studiometa/vue-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
Vue.use(VueMapbox);
// ### mapbox
// import * as VueGoogleMaps from "vue2-google-maps";
let endpoint = process.env.VUE_APP_API_DOMAIN + '/api';
//console.log(store.state);
let graphql = new Graphql({
    uri: endpoint,
    wsActive: false,
    headers: {
        "X-TOKEN": () => store.state.general.token,
        "X-LANGUAGE": () => /^([a-z]{2,3})/i.exec((navigator.language || navigator.userLanguage))[1].toUpperCase(),
        "X-TIMEZONE": () => momentTz.tz.guess(),
        "X-TRANSLATED": () => store.state.translate || ''
    },
});

graphql.setErrorHandler((e) => {
    if (e.networkError) {
        if (
            Array.isArray(e.networkError.result.errors) &&
            e.networkError.result.errors.length > 0
        ) {
            throw new Error(e.networkError.result.errors.find((x) => !!x).message);
        }
    }

    if (Array.isArray(e.graphQLErrors) && e.graphQLErrors.length > 0) {
        throw new Error(e.graphQLErrors.find((x) => !!x).message);
    }
    throw e;
});

Vue.use(graphql.install("apollo"));
Vuex.Store.prototype.$apollo = graphql;

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyBcorLjHA3SY1pah2fOBRzmw1qlWVkvudk",
//     libraries: "places",
//     types: ["(cities)"],
//     componentRestrictions: { country: "us" },
//   },
//   installComponents: true,
// });

Vue.config.productionTip = false;
const app = new Vue({ router, store, i18n, render: (h) => h(App) });
validator.call(app);
app.$mount("#app");
