<template>
  <div class="industry-icon-width">
    <component
      :class="color === 'primary' ? 'industry-icon-primary' : 'industry-icon-gray'"
      :is="get"
    />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
      required: true,
    },
  },
  data() {
    return {
        active: false
    }
  },
  methods: {
      get() {
        return import(`@/assets/img/icons/industry/${this.name}.svg`);
      }
  },
};
</script>
<style lang="scss">
.industry-icon {
  &-gray path {
    fill: #1178b3;
  }
  &-primary path {
    fill: #1178b3;
  }
}
</style>
