import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import _ from 'lodash';
//modules
import general from "./modules/general";
import inquiries from "./modules/inquiries";
import user from "./modules/user"
import messenger from "./modules/messenger"
import notifications from './modules/notifications';
import messages from './modules/messages';
import post from "./modules/landing/post";
import features from "./modules/landing/features";
import public_ from "./modules/public";

// Schema
import checkTokenSchema from "../graphql/me/checkToken.gql";
import getDistance from "@/graphql/me/profile/getDistance.gql";
import switchDistance from "@/graphql/me/profile/switchDistance.gql";
import ALL_PLANS from "@/graphql/public/getAllPlans.graphql";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            key: "Connecter",
            reducer: (state) => ({
                lang: state.general.lang,
                token: state.general.token,
                translate: state.translate
                // errorMessage // this state is not included, no need to set to localStorage
            }),
        }),
    ],
    state: {
        userInfo: {
            distance_unit: 'km'
        },
        translate: null,
        itemKey: 0

    },
    mutations: {
        SET_USER_INFO(state, par) {
            return Vue.set(state, 'userInfo', { ...state.userInfo, ...par });
        },
        INC_ITEM_KEY(state) {
            return state.itemKey++;
        },
        UNSET_USER_INFO(state) {
            return Vue.set(state, 'userInfo', {});
        },
        SET_TRANSLATE(state, translate) {
            state.translate = translate;
            return translate;
        },
        SET_USER_DISTANCE_UNIT(state, par) {
            return Vue.set(state.userInfo, 'distance_unit', par);
        },
    },
    getters: {
        GET_USER_INFO(state) {
            return state.userInfo;
        },
        GET_TRANSLATE(state) {
            return state.translate;
        },
        GET_USER_DISTANCE_UNIT(state) {
            return state.userInfo.distance_unit
        },
        GET_ITEM_KEY(state) {
            return state.itemKey;
        }
    },
    actions: {
        async CHECK_MY_PLAN({ commit, getters }) {
            let selector = await this.$apollo.query(ALL_PLANS, {});
            let plans = selector('**.plans');
            let me = getters.GET_USER_INFO;
            let filtered = _.filter(plans, x => x.type === me.plan.type);
            let check = (
                _.findIndex(filtered, x => x.level > me.plan.level) !== -1
                ||
                me.plan.default === true
            );

            if (check) {
                me.plan.upgrade = true;
            }

            commit('SET_USER_INFO', me);
        },
        async checkToken({ dispatch, commit, state }, { vm = null, par = { token: null }, refresh = false }) {
            try {
                if (_.has(state, 'userInfo.id') && refresh === false) {
                    return state.userInfo;
                }

                if (par.token === null) {
                    par.token = localStorage.getItem('conToken');
                }

                let data = await vm.$apollo.query(checkTokenSchema, par);
                let response = data("**.checkToken");
                commit("SET_USER_INFO", { ...response.member, ...response.token });
                await dispatch('CHECK_MY_PLAN');
                return { ...response.member, ...response.token };
            } catch (e) {
                return null;
            }
        },
        async distanceGet({ commit, state }, { vm = null, par }) {
            try {
                let data = await vm.$apollo.query(getDistance, {});
                let distance = data("**.get");
                commit("SET_USER_DISTANCE_UNIT", distance);
                return distance;
            } catch (e) {
                alert(e.message);
            }
        },
        async distanceSet({ commit, state }, { vm = null, par }) {
            try {
                let distance = await vm.$apollo.mutate(switchDistance, { unit: par });
                commit("SET_USER_DISTANCE_UNIT", par);
                return distance;
            } catch (e) {
                alert(e.message);
            }
        },

    },
    modules: {
        'general': general,
        'inquiries': inquiries,
        'user': user,
        'messenger': messenger,
        'notifications': notifications,
        'messages': messages,
        'public': public_,
        'landing/post': post,
        'landing/features': features
    },
});
