import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faLock,
  faEnvelope,
  faChevronRight,
  faChevronLeft,
  faGlobe,
  faBell,
  faBorderAll,
  faList,
  faSlidersH,
  faArrowLeft,
  faEllipsisV,
  faEye,
  faHome,
  faTimes,
  faUpload,
  faQuestionCircle,
  faPlusCircle,
  faMinusCircle,
    faUserLock,
    faSearch,
    faImage,
    faPaperclip,
    faSmile
} from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// Custom Icon
import {
  offerIcon,
  bulbIcon,
  userIcon,
  folderIcon,
  roleIcon,
  shopIcon,
  locationIcon,
  closeIcon,
  cameraIcon,
  sellerIcon,
  leftArrowIcon,
  energyIcon,
  checkedIcon,
  matchedIcon,
  messageIcon,
  fairsIcon,
  roadIcon,
  askIcon,
  lambIcon,
  connectIcon,
    editIcon,
  blockIcon
} from "../helpers/custom-icon";

library.add(faUserLock);
library.add(faPaperclip);
library.add(faSmile);
library.add(faImage);
library.add(faSearch);
library.add(editIcon);
library.add(blockIcon);
library.add(connectIcon);
library.add(bulbIcon);
library.add(offerIcon);
library.add(faMinusCircle);
library.add(faUpload);
library.add(faPlusCircle);
library.add(lambIcon);
library.add(faEllipsisV);
library.add(faTimes);
library.add(askIcon);
library.add(faLinkedinIn);
library.add(roadIcon);
library.add(faArrowLeft);
library.add(faEye);
library.add(faBorderAll);
library.add(faSlidersH);
library.add(faList);
library.add(faHome);
library.add(faBars);
library.add(faBell);
library.add(userIcon);
library.add(faLock);
library.add(faEnvelope);
library.add(faCommentAlt);
library.add(folderIcon);
library.add(roleIcon);
library.add(shopIcon);
library.add(closeIcon);
library.add(locationIcon);
library.add(sellerIcon);
library.add(cameraIcon);
library.add(leftArrowIcon);
library.add(energyIcon);
library.add(checkedIcon);
library.add(matchedIcon);
library.add(messageIcon);
library.add(fairsIcon);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faGlobe);
library.add(faQuestionCircle);

Vue.component("v-icon", FontAwesomeIcon);
