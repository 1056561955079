import getConnections from "@/graphql/user/getConnections.graphql"
import unFollowUser from "@/graphql/me/profile/unFollow.graphql"
import followUser from "@/graphql/me/profile/follow.graphql"
const user = {
    namespaced: true,
    actions: {
        async getUserConnections({commit}, {vm = null, payload}) {
            let data = await vm.$apollo.query(getConnections, payload)
            let response = data("**.connections")
            return response
        },
        async unfollowUser({commit}, {vm = null, payload}) {
            let data = await vm.$apollo.mutate(unFollowUser, payload)
            let response = data("**.unfollow")
            return response
        },
        async followUser({commit}, {vm = null, payload}) {
            let data = await vm.$apollo.mutate(followUser, payload)
            let response = data("**.follow")
            return response
        }
    }
}

export default user