<template>
    <div id="app" v-if="ww >= 1200">
        <router-view />
        <div>
            <b-row>
                <b-col cols="9">
                    <p class="footer-text">
                        Copyright © 2021 All rights reserved. Connectter OU
                    </p>
                </b-col>

                <b-col
                    cols="3"
                    style="display: flex; justify-content: flex-end"
                >
                    <a href="https://www.connectter.com" target="_blank">
                        <img
                            style="
                                width: 20px;
                                margin-top: 0px;
                                margin-right: 15px;
                            "
                            src="/img/blue-dots.svg"
                            alt=""
                        />
                    </a>
                </b-col>
            </b-row>
        </div>
    </div>
    <div class="screen-low-topdiv" v-else>
        <div class="screen-low-header">
            <img style="" src="/img/logo.svg" alt="" />
        </div>

        <div class="screen-low-title">
            <h4 style="margin-bottom: 50px">:: Low screen size detected ::</h4>

            <h4>{{ ww }} px</h4>
        </div>

        <div class="screen-low-gif">
            <img
                style="margin-top: 0px; margin-right: 0px"
                src="/img/1200low-400.gif"
                alt=""
            />
        </div>
        <h6 class="screen-low-title-bottom">
            Please adjust your screen resolution to 1200 or higher for a better
            experience.
        </h6>
    </div>
</template>

<script>
export default {
    components: {
        // vueCustomScrollbar,
    },
    data() {
        return {
            ww: 0,
        };
    },
    created() {
        let lang = navigator.language.split("-")[0];
        window.scrollTo(0, 0);
        this.$store.commit("general/setLang", lang);
    },
    mounted() {
        this.ww = window.innerWidth;
        window.addEventListener("resize", () => (this.ww = window.innerWidth));
    },
};
</script>
<style lang="scss" >
@import "./assets/scss/index";
#app {
    height: 100vh;
}
</style>
