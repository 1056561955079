'use strict';
function shorter(name, surname, maxLength = 15) {
    let fullname = name + ' ' + surname;
    if (fullname.length <= maxLength) {
        return fullname;
    }

    let names = fullname.split(' ');
    let returning = [];
    let tlength = 0;
    for (let index in names.reverse()) {
        tlength += names[index].length;
        if (tlength > maxLength) {
            returning.push(`${names[index].substr(0, 1).toUpperCase()}.`);
            continue;
        }

        returning.push(names[index]);
    }

    return returning.reverse().join(' ');
}

export default shorter;