<template>
  <b-container style="background: #c5c5c5; width: 1220px" fluid>
    <b-row>
      <b-col cols="12">
        <buyer-card-map></buyer-card-map>
        <seller-card-map></seller-card-map>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import buyerCardMap from "../../components/cards/map/buyerCard.vue";
import sellerCardMap from "../../components/cards/map/sellerCard.vue";

export default {
  components: {
    buyerCardMap,
    sellerCardMap,
  },
};
</script>