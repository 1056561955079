'use strict';
import COUNT_UNREADS_GRAPHQL from "@/graphql/messenger/countUnreads.gql";
import _ from 'lodash';
const notifications = {
    namespaced: true,
    state: {
        badge: 0,
        convIds: {}
    },
    mutations: {
        SET_BADGE(state, badge) {
            return state.badge = badge;
        },
        
        REMOVE_ID(state, id) {
            return _.unset(state, `convIds.${id}`);
        },

        SET_ID(state, id) {
            state.convIds[id] = true;
        }
    },
    getters: {
        total(state) {
            return state.badge;
        },

        checkId(state) {
            return id => !!state.convIds[id];
        }
    },

    actions: {
        async COUNT_UNREADS({ commit }, { vm = null }) {
            if (vm !== null) {
                let sdata = await vm.$apollo.query(COUNT_UNREADS_GRAPHQL, { });
                commit("SET_BADGE", sdata("**.countUnreads"));
                return sdata("**.countUnreads");
            }
        },
    }
};
export default notifications;