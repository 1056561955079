'use strict';
import LIST_POSTS from '@/graphql/landing/listPosts.graphql';
import COUNT_UNVIEWED_POSTS from '@/graphql/landing/countUnviewedPosts.graphql';
import LIKE_POST from '@/graphql/landing/like.graphql';
import UNLIKE_POST from '@/graphql/landing/unlike.graphql'
import PIN_POST from '@/graphql/landing/pin.graphql';
import UNPIN_POST from '@/graphql/landing/unpin.graphql';
import REMOVE_POST from '@/graphql/landing/remove.graphql';
import UPLOAD_FILES from '@/graphql/landing/upload.graphql';
import NEW_POST from '@/graphql/landing/newPost.graphql';
import VIEW_POST from '@/graphql/landing/viewPost.graphql';
import SET_DNSA from '@/graphql/landing/setDoNotShowAgain.graphql';
import GET_DNSA from '@/graphql/landing/doNotShowAgain.graphql';
import Vue from 'vue';
import _ from 'lodash';

const landing = {
    namespaced: true,
    state: {
        filters: {},
        limit: 10,
        cursor: null,
        posts: [],
        unviewedPostNumbers: {
            STATUS: 0,
            INSIGHT: 0,
            PRODUCT: 0,
            INQUIRY: 0
        },
        welcomeDoNotShowAgain: false,
        next: true,
        loadings: {
            query: false,
            mutate: false
        }
    },
    getters: {
        filters: state => state.filters,
        limit: state => state.limit,
        cursor: state => state.cursor,
        posts: state => state.posts,
        loadings: state => state.loadings,
        welcomeDoNotShowAgain: state => state.welcomeDoNotShowAgain,
        unviewedPostNumbers: state => state.unviewedPostNumbers
    },
    mutations: {
        SET_UNVIEWED_POST_NUMBER(state, { key, value }) {
            state.unviewedPostNumbers[key] = value;
        },

        PUSH_POST(state, post) {
            state.posts.push(post);
        },
        
        SET_NEXT(state, next) {
            state.next = next;
        },

        SET_WELCOME_DO_NOT_SHOW_AGAIN(state, status) {
            state.welcomeDoNotShowAgain = status;
        },

        UNSHIFT_POST(state, post) {
            state.posts.unshift(post);
        },

        RESET_POSTS(state) {
            state.cursor = null;
            Vue.set(state, 'posts', []);
        },
        
        SET_CURSOR(state, cursor) {
            state.cursor = cursor;
        },

        SET_LOADINGS(state, { type, loading }) {
            state.loadings[type] = loading;
        },

        SET_FILTERS(state, filters) {
            state.cursor = null;
            state.next = true;
            Vue.set(state, 'posts', []);
            state.filters = filters;
        },

        LIKE(state, id) {
            let index = _.findLastIndex(state.posts, x => x.id === id);
            if (index !== -1 && state.posts[index].liked == false) {
                state.posts[index].like_count++;
                state.posts[index].liked = true;
            }

            return true;
        },

        VIEW(state, id) {
            let index = _.findLastIndex(state.posts, x => x.id === id);
            if (index !== -1 && state.posts[index].viewed == false) {
                state.posts[index].viewed = true;
                state.unviewedPostNumbers[state.posts[index].type]--;
            }

            return true;
        },

        PIN(state, id) {
            let index = _.findLastIndex(state.posts, x => x.id === id);
            if (index !== -1 && state.posts[index].pinned == false) {
                state.posts[index].pinned = true;
            }
            
            return true;
        },

        REPOSTED(state, id) {
            let index = _.findLastIndex(state.posts, x => x.id === id);
            if (index !== -1 && state.posts[index].reposted == false) {
                state.posts[index].reposted = true;
            }

            return true;
        },

        UNPIN(state, id) {
            let index = _.findLastIndex(state.posts, x => x.id === id);
            if (index !== -1 && state.posts[index].pinned == true) {
                state.posts[index].pinned = false;
            }
            
            return true;
        },

        REMOVE_POST(state, id) {
            let index = _.findLastIndex(state.posts, x => x.id === id);
            let check = _.get(state, `posts[${index}].content.ref.id`);
            if (index !== -1) {
                state.posts.splice(index, 1);
            }

            if (check) {
                let pindex = _.findIndex(state.posts, x => x.id === check);
                state.posts[pindex].reposted = false;
            }

            return true;
        },

        UNLIKE(state, id) {
            let index = _.findLastIndex(state.posts, x => x.id === id);
            if (index !== -1 && state.posts[index].liked == true) {
                state.posts[index].like_count--;
                state.posts[index].liked = false;
            }
            return true;
        }
    },

    actions: {
        async LOAD_NEXT({ commit, state }) {
            try {
                commit('SET_LOADINGS', { type: 'query', loading: true });
                let params = _.omit(state, ['posts', 'loadings', 'next']);
                let selector = await this.$apollo.query(LIST_POSTS, params);
                let posts = selector('**.list');
                let cursor = selector('**.next_cursor');
                if (_.size(posts) > 0) {
                    _.forEach(posts, post => commit('PUSH_POST', post));
                    commit('SET_CURSOR', cursor);
                    return posts;
                }

                commit('SET_NEXT', false);
                return posts;
            } catch (error) {
                throw error;
            } finally {
                commit('SET_LOADINGS', { type: 'query', loading: false });
            }
        },

        async COUNT_UNVIEWED_POSTS({ commit, state }) {
            try {
                commit('SET_LOADINGS', { type: 'query', loading: true });
                let selector = await this.$apollo.query(COUNT_UNVIEWED_POSTS, {});
                let counts = selector('**.countUnviewedPosts');

                for (let key in state.unviewedPostNumbers) {
                    let finded = _.find(counts, { type: key });
                    if (finded) {
                        commit('SET_UNVIEWED_POST_NUMBER', { key, value: finded.count });
                    }
                }

                return counts;
            } catch (error) {
                throw error;
            } finally {
                commit('SET_LOADINGS', { type: 'query', loading: false });
            }
        },

        async UPLOAD_FILES({ commit }, { files, options = {} }) {
            try {
                commit('SET_LOADINGS', { type: 'mutate', loading: true });
                let selector = await this.$apollo.mutate(UPLOAD_FILES, { files }, options);
                return selector('**.upload');
            } catch (error) {
                throw error;
            } finally {
                commit('SET_LOADINGS', { type: 'mutate', loading: false });
            }
        },

        async NEW_POST({ commit }, content) {
            try {
                commit('SET_LOADINGS', { type: 'mutate', loading: true });
                let selector = await this.$apollo.mutate(NEW_POST, { content });
                commit('UNSHIFT_POST', selector('**.newPost'));
                return selector('**.newPost');
            } catch (error) {
                throw error;
            } finally {
                commit('SET_LOADINGS', { type: 'mutate', loading: false });
            }
        },

        async LIKE({ commit }, post_id) {
            commit('LIKE', post_id);
            try {
                await this.$apollo.mutate(LIKE_POST, { post_id });
            } catch (e) {
                console.log(e);
                commit('UNLIKE', post_id);
            }
        },

        async GET_DO_NOT_SHOW_AGAIN({ commit }) {
            let selector = await this.$apollo.query(GET_DNSA, { type: 'WELCOME' });
            commit('SET_WELCOME_DO_NOT_SHOW_AGAIN', selector('**.doNotShowAgain'));
        },

        async SET_DO_NOT_SHOW_AGAIN({ commit }) {
            let selector = await this.$apollo.mutate(SET_DNSA, { type: 'WELCOME' });
            commit('SET_WELCOME_DO_NOT_SHOW_AGAIN', selector('**.setDoNotShowAgain'));
        },

        async UNLIKE({ commit }, post_id) {
            commit('UNLIKE', post_id);
            try {
                await this.$apollo.mutate(UNLIKE_POST, { post_id });
            } catch (e) {
                console.log(e);
                commit('LIKE', post_id);
            }
        },

        async PIN({ commit }, post_id) {
            commit('PIN', post_id);
            try {
                await this.$apollo.mutate(PIN_POST, { post_id });
            } catch (e) {
                console.log(e);
                commit('UNPIN', post_id);
            }
        },

        async VIEW({ commit }, id) {
            try {
                await this.$apollo.mutate(VIEW_POST, { id });
                commit('VIEW', id);
            } catch (e) {
                console.log(e);
            }
        },

        async UNPIN({ commit }, post_id) {
            commit('UNPIN', post_id);
            try {
                await this.$apollo.mutate(UNPIN_POST, { post_id });
            } catch (e) {
                console.log(e);
                commit('PIN', post_id);
            }
        },

        async REMOVE_POST({ commit }, id) {
            try {
                await this.$apollo.mutate(REMOVE_POST, { id });
                commit('REMOVE_POST', id);
            } catch (error) {
                throw error;
            }
        }
    }

};
export default landing;