import { WebSocketLink } from "apollo-link-ws";
import { ApolloLink, concat, split } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { buildAxiosFetch } from "@lifeomic/axios-fetch";
import { getMainDefinition } from "apollo-utilities";
import ApolloClient from "apollo-client";
import axios from "axios";
import MessageTypes from "subscriptions-transport-ws/dist/message-types";
import _ from "lodash";
import moment from 'moment-timezone';
//-----------------------------------------------------------------------------------------
const DATE_ISO = /^\d{4,4}-\d{2,2}-\d{2,2}T\d{2,2}:\d{2,2}:\d{2,2}(?:[.a-zA-Z0-9]{1,6})?$/i;
const DATETIME = /^\d{4,4}-\d{2,2}-\d{2,2} \d{2,2}:\d{2,2}:\d{2,2}$/i;
function converttz(value) {
    if (_.isArray(value)) {
        return _.map(value, x => converttz(x));
    }

    if (_.isPlainObject(value)) {
        return _.mapValues(value, x => converttz(x));
    }

    if (_.isString(value) && DATE_ISO.test(value)) {
        return moment.tz(value, 'UTC').tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');
    }

    if (_.isString(value) && DATETIME.test(value)) {
        return moment.tz(value, 'UTC').tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');
    }

    return value;
}
const stc = async (callback) => {
    try {
        return await callback();
    } catch (e) {
        return e;
    }
};

function buildHeaders() {
    let headers = {};
    for (let key in this.headers) {
        headers[key] =
            typeof this.headers[key] === "function"
                ? this.headers[key]()
                : typeof this.headers[key];
    }

    return headers;
}

function omitter(value) {
    if (_.isObject(value) === false && _.isArray(value) === false) {
        return value;
    }

    if (_.isObject(value)) {
        return _.mapValues(_.omit(value, '__typename'), sv => omitter(sv));
    }

    if (_.isArray(value)) {
        return _.map(value, sv => omitter(sv));
    }


    return value;
}

function* mapper(value, parent = '') {
    if (_.isPlainObject(value) === false && _.isArray(value) === false) {
        yield { key: parent, value };
        return;
    }

    yield { key: parent, value };
    let its = [];
    for (let key in value) {
        its.push(mapper(value[key], `${parent}/${key}`.replace(/^\//gi, '')));
    }


    while (_.size(its) > 0) {
        for (let i = 0; i < _.size(its); i++) {
            let { done, value } = its[i].next();
            if (done === true) {
                its.splice(i, 1);
                continue;
            }

            yield { key: value['key'], value: value['value'] };
        }
    }

}

function xpath(object, xp = undefined, rtn = false) {
    if (!xp) {
        return object;
    }

    if (_.isString(xp)) {
        xp = xp.replace(/\./gi, '/');
        xp = xp.replace(/\*{2}/gi, '[a-zA-Z0-9_\\-\\/]+');
        xp = xp.replace(/\*/gi, '[a-zA-Z0-9_-]+');
    }

    let srgx = _.isRegExp(xp) ? xp : new RegExp('^' + xp + '$', 'i');
    for (let { key, value } of mapper(object)) {
        if (srgx.test(key)) {
            return converttz(rtn === false ? value : omitter(value));
        }
    }

    return null;
}

function GraphQL({ uri, headers = {}, wsActive = false }) {
    if (!new.target) throw new Error("New ile çağırılmalıdır");
    let url = new URL(uri);
    let ssl = url.protocol === "https:";
    let port = url.port ? url.port : ssl === true ? 443 : 80;
    let httpcs = `${ssl ? "https://" : "http://"}${url.hostname}:${port}${url.pathname
        }`;
    let wscs = `${ssl ? "wss://" : "ws://"}${url.hostname}:${port}${url.pathname
        }`;
    //--------------------------------------------------------------------------------------
    this.headers = headers;
    //--------------------------------------------------------------------------------------

    let wsLink = wsActive
        ? new WebSocketLink({
            uri: wscs,
            options: { reconnect: true, connectionParams: buildHeaders.bind(this) },
        })
        : null;
    let uploadLink = createUploadLink({
        uri: httpcs,
        fetch: buildAxiosFetch(
            axios,
            (config, input, init) => ({ ...config, ...init })
        ),
    });
    let middleware = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                ...buildHeaders.call(this),
                ...operation.getContext().headers,
            },
        });
        return forward(operation);
    });

    let soperator = (p) => {
        let x = getMainDefinition(p.query);
        return x.kind === "OperationDefinition" && x.operation === "subscription";
    };

    let link = wsActive
        ? split(soperator, wsLink, concat(middleware, uploadLink))
        : concat(middleware, uploadLink);
    this.client = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: true,
        defaultOptions: {
            query: {
                fetchPolicy: "no-cache"
            },
            mutate: {
                fetchPolicy: 'no-cache'
            }
        },
    });

    this.install = (name) => {
        return {
            install: (Vue, options) => {
                Vue.prototype["$" + name] = this;
            },
        };
    };

    this.resetWs = () => {
        if (wsActive) {
            let operations = Object.assign({}, wsLink.subscriptionClient.operations);
            wsLink.subscriptionClient.close(true);
            wsLink.subscriptionClient.connect();
            for (let id in operations)
                wsLink.subscriptionClient.sendMessage(
                    id,
                    MessageTypes.GQL_START,
                    operations[id].options
                );
        }
    };

    this.query = async (query, variables = {}, context) => {
        if (typeof variables !== "object")
            throw new Error("variables obje olmalıdır");
        if (typeof headers !== "object") throw new Error("headers obje olmalıdır");
        let response = await stc(() =>
            this.client.query({ query, variables, context })
        );
        if (response instanceof Error) {
            if (this.cb) return this.cb(response);
            throw response;
        }

        return xpath.bind(this, response.data);
    };

    this.mutate = async (mutation, variables = {}, context = {}) => {
        if (typeof variables !== "object")
            throw new Error("variables obje olmalıdır");
        if (typeof headers !== "object") throw new Error("headers obje olmalıdır");
        let response = await stc(() =>
            this.client.mutate({ mutation, variables, context })
        );
        if (response instanceof Error) {
            if (this.cb) return this.cb(response);
            throw response;
        }

        return xpath.bind(this, response.data);
    };

    this.subscribe = (query, variables, next, error) => {
        if (!wsActive) throw new Error("Subscribe özelliği aktif değil");
        if (typeof variables !== "object")
            throw new Error("variables obje olmalıdır");
        if (typeof next !== "function") throw new Error("next fonksiyon olmalıdır");
        if (typeof error !== "function")
            throw new Error("error fonksiyon olmalıdır");
        return this.client
            .subscribe({ query, variables })
            .subscribe({
                next: (response) =>
                    next(xpath.bind({ map: mapper(response.data), obj: response.data })),
                error,
            });
    };

    this.setErrorHandler = (cb) => {
        if (typeof cb !== "function")
            throw new Error("callback fonksiyon olmalıdır");
        this.cb = cb;
    };
}

export default GraphQL;
