'use strict';
export default {
    monthly: {
        day: 30,
        month: 1,
        name: 'Monthly'
    },
    // quarterly: {
    //     day: 30 * 3,
    //     month: 3,
    //     name: 'Quarterly'
    // },
    yearly: {
        day: 30 * 12,
        month: 12,
        name: 'Yearly'
    }
};