import convertion from "@/graphql/messenger/convertion.graphql"
import messengerConversation from "@/graphql/messenger/messengerConversation.graphql"
const messenger = {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async getConversationStart({ commit }, { vm = null, par }) {
      if (vm !== null) {
        let data = await vm.$apollo.mutate(convertion, par);
          return data("**.startConversation");
      }
    },
    async getConversationMessenger({ commit }, { vm = null, par }) {
      if (vm !== null) {
        let data = await vm.$apollo.query(messengerConversation, par);
        let response = data("**.conversation");
        return response;
      }
    },

  }
};
export default messenger
