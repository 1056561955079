import i18n from "../../plugins/i18n";
import getInquiries from "@/graphql/me/getInquiries.gql";
import getIndustries from "@/graphql/public/getIndustry.gql"
import getCurrencies from "@/graphql/public/getCurrencies.gql"
import getUnit from "@/graphql/public/getUnit.gql"
import addProduct from "@/graphql/me/addProduct.gql";
import addInqury from "@/graphql/me/addInquiry.gql";
import getTitles from "@/graphql/public/getTitles.gql"
import getPositions from "@/graphql/public/getPositions.gql"
import updateInquries from "@/graphql/me/updateInquiry.gql"
import getProducts from "@/graphql/me/getProducts.gql"
import updateProduct from "@/graphql/me/updateProduct.gql";
const general = {
  namespaced: true,
  state: {
    userList: {
      list: [],
    },
  },
  mutations: {
    setUserList(state, par) {
      state.userList = par;
    },
  },
  getters: {
    getUserList(state) {
      return state.userList;
    },
  },
  actions: {
    async fetchInquiriesList({ commit }, { vm = null, payload }) {
      if (vm !== null) {
        let data = await vm.$apollo.query(getInquiries, payload);
        let response = data("**.list");
         commit("setUserList", response);
         return response
      }
    },
    async getIndustries({commit}, {vm = null, payload}) {
      if(vm !== null) {
        let data  = await vm.$apollo.query(getIndustries, payload)
        let response = data("**.industries");
        return response
      }
    },
    async getCurrencies({commit}, {vm = null}) {
      if(vm !== null) {
        let data  = await vm.$apollo.query(getCurrencies)
        let response = data("**.currencies");
        return response
      }
    },
    async getUnits({commit}, {vm = null}) {
      if(vm !== null) {
        let data  = await vm.$apollo.query(getUnit)
        let response = data("**.units");
        return response
      }
    },
    async addProduct({commit}, {vm = null, payload}) {
      if(vm !== null) {
        let data = await vm.$apollo.mutate(addProduct, {
          product: payload,
        })
        return data
      }
    },
    async addInqury({commit}, {vm = null, payload}) {
      if(vm !== null) {
        let data = await vm.$apollo.mutate(addInqury, {
          inquiry: payload,
        })
        return data
      }
    },
    async getTitles({commit}, {vm = null}) {
      if(vm !== null) {
        let data = await vm.$apollo.query(getTitles)
        return data
      }
    },
    async getPositions({commit}, {vm = null, payload}) {
      if(vm !== null) {
        let data = await  vm.$apollo.query(getPositions, payload)
        return data
      }
    },
    async updateInquryStatus({commit}, {vm = null, payload}) {
      let data = await vm.$apollo.mutate(updateInquries, {inquiry: payload})
      return data
    },
    async getListings({commit}, {vm= null, payload}) {
      if(vm !== null) {
        let data = await vm.$apollo.query(getProducts, payload)
        let response = data("**.list");
        return response
      }
    },
    async updateProduct({commit}, {vm= null, payload}) {
      let response = await vm.$apollo.mutate(updateProduct, {product: payload})
      return response
    }
  },
};

export default general;
