import Vue from "vue";
import i18n from "./i18n";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
  configure,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`${field}`);
    return i18n.t("validations." + values._rule_, values);
  },
});
// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
