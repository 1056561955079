import Vue from "vue";
import VueRouter from "vue-router";
import ViewAllCard from "../views/cards/viewsAllCard.vue";
import store from "../store";
import notFound from "../views/404.vue"
import _ from 'lodash';
Vue.use(VueRouter);


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((error) => {
    });
};


const routes = [
    {
        path: "/",
        name: "Home",
        redirect: "/auth/login",
    },
    {
        path: "/landing",
        name: "landing",
        component: () => import("../views/home/landing"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/card-views",
        name: "Card Views",
        component: ViewAllCard,
    },
    {
        path: "/auth",
        name: "Auth Home",
        component: () => import("../layouts/auth/"),
        redirect: "/auth/login",
        children: [
            {
                path: "/auth/login",
                component: () => import("../views/auth/login/login"),
                meta: {
                    guest: true,
                },
            },
            {
                path: "/auth/register",
                component: () => import("../views/auth/register/register"),
                meta: {
                    guest: true,
                },
            },
            {
                path: "/auth/confirm-mail",
                component: () => import("../views/auth/confirmation/confirmMail"),
                meta: {
                    guest: true,
                },
            },
            {
                path: "/auth/confirmation/:id",
                component: () => import("../views/auth/confirmation/confirmation"),
                meta: {
                    guest: true,
                },
            },
            {
                path: "/auth/forgot-password",
                component: () => import("../views/auth/forgot-password/forgotPassword"),
                meta: {
                    guest: true,
                },
            },
            {
                path: "/auth/reset-password/:id",
                component: () => import("../views/auth/reset-password/resetPassword"),
                meta: {
                    guest: true,
                },
            },
        ],
    },
    {
        path: "/register-steps",
        name: "Register Step",
        redirect: "/register-steps/step-1",
        component: () =>
            import(/* webpackChunkName: "about" */ "../layouts/register-steps"),
        children: [
            {
                path: "/register-steps/step-1",
                component: () => import("../views/register-steps/step1"),
                meta: {
                    guest: true,
                },
            },
            {
                path: "/register-steps/step-2",
                component: () => import("../views/register-steps/step2"),
                meta: {
                    guest: true,
                },
            },
            {
                path: "/register-steps/step-3",
                component: () => import("../views/register-steps/step3"),
                meta: {
                    guest: true,
                },
                children: [
                    {
                        path: "/register-steps/step-3/inqury-add",
                        component: () => import("../views/register-steps/inqury"),
                    },
                    {
                        path: "/register-steps/step-3/product-add",
                        component: () => import("../views/register-steps/product"),
                    },
                    {
                        path: "/register-steps/step-3/products",
                        component: () => import("../views/register-steps/productList"),
                    },
                    {
                        path: "/register-steps/step-3/inquiry-edit/:id",
                        component: () => import("../views/register-steps/inquiryEdit"),
                    },
                    {
                        path: "/register-steps/step-3/product-edit/:id",
                        component: () => import("../views/register-steps/productEdit"),
                    },
                ],
            },
            {
                path: "/register-steps/step-4",
                component: () => import("../views/register-steps/step4"),
            },
        ],
    },
    {
        path: "/app",
        name: "Connectter App",
        component: () => import("../layouts/app"),
        redirect: "/app/dashboard",
        children: [
            {
                path: "/app/dashboard",
                component: () => import("../views/app/dashboard"),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/app/user/profile/:id",
                component: () => import("../views/app/user/profile/profile"),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/app/my/requests",
                component: () => import("../views/app/my/requests"),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/app/my/listings",
                component: () => import("../views/app/my/listing"),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/app/messenger/:id?",
                component: () => import("../views/app/messenger/messenger"),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/app/my/connections",
                component: () => import("../views/app/my/connections"),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/app/profile/settings/:tab?/:subtab?",
                component: () => import("../views/app/Profile/Setting"),
                meta: {
                    requiresAuth: true,
                },
            }

        ],
    },
    {
        path: '/app/thank-you',
        component: () => import("../views/app/thankYou"),
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '*',
        component: notFound,
        meta: {
            requiresAuth: false,
        }
    }
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    let par = { token: localStorage.getItem("conToken") };
    let vm = router.app;
    let isAuth = await store.dispatch("checkToken", { vm, par });
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        let condition = (
            localStorage.getItem("conToken") == null
            ||
            _.has(isAuth, 'id') === false
            ||
            isAuth.on_boarding === true
        );

        if (condition) {
            localStorage.setItem("conToken", null);
            next({ path: "/auth/login" });
        } else {
            next();
        }
    }
    if (to.matched.some((record) => record.meta.guest)) {
        if (_.has(isAuth, 'id') === false || isAuth.on_boarding === true) {
            return next();
        }

        if (localStorage.getItem("conToken") == null || _.has(isAuth, 'id') === false) {
            localStorage.setItem("conToken", null);
            next();
        } else {
            next({ path: "/app/dashboard" });
        }
    } else {
        next();
    }
});

export default router;
