<template>
  <div
    v-if="loading"
    class="d-flex flex-column justify-content-center align-items-center h-100 transformY50"
  >
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <h3 class="d-block">{{ $t('General.loading') }}</h3>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    loading: false
  }
};
</script>
