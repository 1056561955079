import { extend } from 'vee-validate';
import _ from 'lodash';
function init() {
    extend(
        'itemName',
        value => {
            if (_.size(value) > 40) {
                return 'Maximum 40 characters';
            }

            if (_.size(value) < 4) {
                return 'Maximum 3 words'; // this.$t('key.key.key')
            }

            let size = _.size(_.split(value, ' '));
            if (size < 2 || size > 12) {
                return 'Minumum 2 words';
            }

            return true;
        }
    );

    extend(
        'itemDescription',
        value => {
            if (_.size(value) > 1200) {
                return 'Maxiumum 1200 characters';
            }

            if (_.size(value) < 150) {
                return 'Minimum 150 characters';
            }

            return true;
        }
    );
}

export default init;