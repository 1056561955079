import { mapGetters, mapState } from "vuex";
import PLAN_TYPES from "@/consts/plans";
import mezr from "@/plugins/mezr";
import shorter from '@/helpers/shorter';
import makeDelay from '@/helpers/makeDelay';
import _ from 'lodash';

import DETECT_LANGUAGE from '@/graphql/common/detectLanguage.graphql';
import MY_LANGUAGE from '@/graphql/me/profile/getMyLang.gql';
//---------------------------------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------------------------------
export default {
    data() {
        return {
            updateDelay: makeDelay(100),
            mapAccessToken: "pk.eyJ1IjoiY2FudTkiLCJhIjoiY2tkYW5jNnZjMGpqbzM0cnhheGlhMDJjayJ9.TynY2B9VpBjKI5GOXtSNMA",
            mapBoxStyle: "mapbox://styles/canu9/ckdanejg91d7o1iqathzm7n5l",
            API_DOMAIN: process.env.VUE_APP_API_DOMAIN,
            CDN_IMAGE_PREFIX: process.env.VUE_APP_IMAGE_CDN_ADDRESS,
            CDN_FILE_PREFIX: process.env.VUE_APP_FILE_CDN_ADDRESS,
            CDN_VIDEO_PREFIX: process.env.VUE_APP_VIDEO_CDN_ADDRESS,
            PLAN_TYPES: PLAN_TYPES,
            shorter: shorter,
            myLanguage: 'EN',
            years: [
                {
                    id: '1-3',
                    title: "1-3 Years",
                },
                {
                    id: '4-7',
                    title: "4-7 Years",
                },

                {
                    id: '8-14',
                    title: "8-14 Years",
                },
                {
                    id: '15-25',
                    title: "15-20 Years",
                },
                {
                    id: '21-25',
                    title: "21-25 Years",
                },
                {
                    id: '26-35',
                    title: "26-35 Years",
                },
                {
                    id: '36-+',
                    title: "36+ Years",
                },
            ],
            number_of_employees: [
                {
                    id: '1-10',
                    title: "1-10",
                },
                {
                    id: '11-50',
                    title: "11-50",
                },
                {
                    id: '51-100',
                    title: "51-100",
                },
                {
                    id: '101-500',
                    title: "101-500",
                },
                {
                    id: '501-1000',
                    title: "501-1000",
                },
                {
                    id: '1000-+',
                    title: "1000+",
                },
            ],
        }
    },
    computed: {
        ...mapGetters({ getUserList: "inquiries/getUserList", GET_USER_INFO: "GET_USER_INFO" }),
        ...mapGetters(['GET_TRANSLATE']),
        userRoleType: {
            get() {
                if (window.localStorage.getItem('user_type') === 'SELLER' || window.localStorage.getItem('user_type') === 'BUYER') {
                    return window.localStorage.getItem('user_type')
                }
                window.localStorage.setItem('user_type', this.GET_USER_INFO.type)
                return window.localStorage.getItem('user_type')
            },
            set(val) {
                window.localStorage.setItem('user_type', val)
                return window.localStorage.getItem('user_type')
            }
        }
    },
    methods: {
        numberFixer(number) {
            return (
                `${number}`
                    .split('')
                    .reverse()
                    .join('')
                    .replace(/(\d{3})/g, '$1.')
                    .replace(/\.$/g, '')
                    .split('')
                    .reverse()
                    .join('')
            );
        },
        async checkLang(text) {
            let selector = await this.$apollo.query(DETECT_LANGUAGE, { text });
            let language = selector('**.detectLanguage');
            return this.myLanguage === language;
        },
        appScrollTop() {
            document.querySelector('.app-content').scrollTop = 0;
        },
        calcHeights() {
            let calcs = document.querySelectorAll('.calc');
            for (let calc of calcs) {
                this.$nextTick(
                    () => {
                        let { top } = mezr.offset(calc, 'margin');
                        calc.style.height = `calc(100vh - ${top}px - 45px)`;
                        calc.style['max-height'] = `calc(100vh - ${top}px - 45px)`;
                    }
                );
            }
        },

        // calc10Heights() {
        //     let calc10s = document.querySelectorAll('.calc10');
        //     for (let calc10 of calc10s) {
        //         this.$nextTick(
        //             () => {
        //                 let { top } = mezr.offset(calc10, 'margin');
        //                 calc10.style.height = `calc(100vh - ${top}px - 10px)`;
        //                 calc10.style['max-height'] = `calc(100vh - ${top}px - 10px)`;
        //             }
        //         );
        //     }
        // },

        calcBoost() {
            this.calcHeights();
            // this.calc10Heights();
        },

        async startConversation(uid) {
            let par = {
                to_uid: uid
            }
            let messengerId = await this.$store.dispatch('messenger/getConversationStart', { vm: this, par })
            return this.$router.push(`/app/messenger/${messengerId}`);
        },
        async getUserProfile(uid) {
            if (uid.length < 4) {
                return false
            }
            await this.$router.push({ path: `/app/user/profile/${uid}` })
        },
        getIndustryIcon(iconName) {
            let icon = "";
            try {
                icon = require("@/assets/img/industries/" + (iconName || 'none') + ".svg");
            } catch (err) {
                icon = "";
            }
            return icon;
        },
        getValidationState({ dirty, validated, valid = null }) {
            if (dirty || validated) {
                return valid;
            }
            return null;
        },
        openAddItemModal() {
            let el = document.querySelector('#createItemButton');
            if (el) {
                el.click();
            }
        },
        openAddInquiryModal() {
            this.$bvModal.show('itemAddInqury')
        },

        openAddProductModal() {
            this.$bvModal.show('itemAdd')
        },

        makeToast(par) {
            this.$bvToast.toast(par.message, {
                toaster: "b-toaster-top-center",
                title: par.title,
                variant: par.type,
                solid: true,
                "no-auto-hide": par.hide,
            });
        },
        getDefault(arr) {
            return arr.find((item) => item.default === true);
        },
        findSubItemByLang(arr) {
            let finded = _.find(arr, x => x.language === _.toUpper(this.GET_TRANSLATE));
            if (!finded) {
                return _.find(arr, x => x.default);
            }
            return finded;
        },
        getAvatar(par, size, cache = true) {
            if (!par) {
                return "/img/no-avatar.png"
            }
            return `${par}?size=${size}x${size}` + (cache === false ? `&v=${new Date().getTime()}`: '')
        },
        scrollToTop() {
            setTimeout(() => {
                let el = document.getElementsByTagName("BODY")[0];
                el.scrollIntoView({ behavior: "smooth" });
            }, 100);
        },
    },

    mounted() {
        // this.calcHeights();
        this.updateDelay(this.calcBoost);
        window.addEventListener('resize', () => this.updateDelay(this.calcBoost));
    },
    updated() {
        this.updateDelay(this.calcBoost);
    },
    async created() {
        let mselector = await this.$apollo.query(MY_LANGUAGE, {});
        this.myLanguage = mselector('**.code');
    }

};
