import COMPANY_SEARCH from '@/graphql/public/companySearch.graphql';
const public_ = {
    namespaced: true,
    state: {
        companies: [],
        text: '',
        loading: false
    },
    getters: {
        companies: state => state.companies,
        text: state => state.text,
        loading: state => state.loading,
    },
    mutations: {
        SET_COMPANIES(state, companies) {
            state.companies = companies;
        },
        
        SET_TEXT(state, text) {
            state.text = text;
        },

        SET_LOADING(state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        async COMPANY_SEARCH({ commit }, { text }) {
            try {
                commit('SET_LOADING', true);
                commit('SET_TEXT', text);
                let selector = await this.$apollo.query(COMPANY_SEARCH, { text });
                commit('SET_COMPANIES', selector('**.companySearch'));
            } catch (error) {
                console.log(error);
            } finally {
                commit('SET_LOADING', false);
            }
        }
    }
};

export default public_;