import i18n from "../../plugins/i18n";
const general = {
  namespaced: true,
  state: {
    userType: null,
    token: localStorage.getItem("conToken"),
    lang: null,
    openSubMenu: false,
    email: null,
    completeStep: null,
  },
  mutations: {
    SET_USER_TYPE(state, par) {
      return (state.userType = par);
    },
    setLang(state, par) {
      state.lang = par;
      i18n.locale = par;
    },
    setToken(state, par) {
      localStorage.setItem("conToken", par);
      return (state.token = par);
    },
    setCompleteStep(state, par) {
      state.completeStep = par;
    },
    setEmail(state, par) {
      state.email = par;
    },
    setSubMenu(state, par) {
      state.openSubMenu = par;
    },
  },
  getters: {
    getUserType(state) {
      return state.userType;
    },
    getToken(state) {
      return state.token;
    },
    getEmail(state) {
      return state.email;
    },
    getCompleteStep(state) {
      return state.completeStep;
    },
    getLang(state) {
      return state.lang;
    },
    getSubMenu(state) {
      return state.openSubMenu;
    },
  },
};

export default general;
