import Vue from 'vue';
import i18n from '../plugins/i18n'

function m2e(code) {

}

function gqlep(e) {
    let lang = i18n.messages[i18n.locale]
    let msg = lang
    let x = e.split('.')
    console.log(x)
    x.shift()
    if(x.length === 0) {
        return lang['GLOBAL_ERROR']
    }

    for(let item of x) {
        msg = msg[item] 
    }
    if(msg) {
        return msg
    }
    return lang['GLOBAL_ERROR']

}


Vue.filter('m2e', m2e);
Vue.filter('gqlep', gqlep);


export default true;