'use strict';
import COUNT_ASSETS from '@/graphql/landing/countAssets.graphql';
import ALL_PLANS from "@/graphql/public/getAllPlans.graphql";
import COM_RATE_SUGGESTIONS from "@/graphql/landing/comRateSuggestions.graphql";
import MY_PRODUCTS from "@/graphql/landing/myProducts.graphql";
import MY_INQUIRIES from "@/graphql/landing/myInquiries.graphql";
import MATCHINGS from "@/graphql/landing/matchings.graphql";
import FOLLOW from "@/graphql/me/profile/follow.graphql"
import CHART_OF_YEAR from '@/graphql/landing/chartOfYear.graphql';
import SET_TAGS from '@/graphql/me/addTags.gql';
import GET_TAGS from '@/graphql/me/profile/getTags.graphql'
import SOCIAL_ACCOUNTS from '@/graphql/landing/socialAccounts.graphql'
import moment from 'moment';
import Vue from 'vue';
import _ from 'lodash';
import shorter from '@/helpers/shorter';

const landing = {
    namespaced: true,
    state: {
        params: {
            assets: {
                uid: null
            }
        },
        variables: {
            loadings: {
                query: false,
                mutate: false,
                process: false
            },
            assets: {
                connections: 0,
                inquiries: 0,
                products: 0,
                matchings: {
                    buyer: 0,
                    seller: 0
                }
            },
            plan: {},
            cras: {},
            matchings: [],
            chartOfYear: [],
            tags: [],
            socialAccounts: []
        }
    },
    getters: {
        variablesAssets: state => state.variables.assets,
        variablesPlan: state => state.variables.plan,
        variablesCras: state => state.variables.cras,
        variablesMatchings: state => state.variables.matchings,
        variablesChartOfYear: state => state.variables.chartOfYear,
        variablesTags: state => state.variables.tags,
        variablesLoadings: state => state.variables.loadings,
        variablesSocialAccounts: state => state.variables.socialAccounts
    },
    mutations: {
        SET_VARIABLES_ASSETS(state, assets) {
            state.variables.assets = assets;
        },

        SET_SOCIAL_ACCOUNTS(state, accounts) {
            state.variables.socialAccounts = accounts;
        },

        SET_PARAMS_ASSETS(state, assets) {
            state.params.assets = assets;
        },
        
        SET_VARIABLES_LOADINGS(state, { type, loading }) {
            state.variables.loadings[type] = loading;
        },

        SET_VARIABLES_PLAN(state, plan) {
            state.variables.plan = plan;
        },

        SET_VARIABLES_CRAS(state, cras) {
            state.variables.cras = cras;
        },

        SET_VARIABLES_MATCHINGS(state, matchings) {
            Vue.set(state.variables, 'matchings', matchings);
        },
        
        REMOVE_VARIABLES_MATCHINGS(state, uid) {
            let index = _.findIndex(state.variables.matchings, x => x.uid === uid);
            if (index !== -1) {
                state.variables.matchings.splice(index, 1);
            }
        },

        SET_VARIABLES_CHART_OF_YEAR(state, months) {
            Vue.set(state.variables, 'chartOfYear', months);
        },

        SET_VARIABLES_TAGS(state, tags) {
            Vue.set(state.variables, 'tags', tags);
        },
    },

    actions: {
        async COUNT_ASSETS({ commit, state }) {
            try {
                commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: true });
                let selector = await this.$apollo.query(COUNT_ASSETS, state.params.assets);
                let connections = selector('**.total');
                let products = selector('**.products.count');
                let inquiries = selector('**.inquiries.count');
                let matchings = {
                    seller: selector('**.sellerMatchings'),
                    buyer: selector('**.buyerMatchings')
                };
                commit('SET_VARIABLES_ASSETS', { connections, products, inquiries, matchings });
            } catch (error) {
                throw error;
            } finally {
                commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: false });
            }
        },

        async CHECK_MY_PLAN({ commit, rootState }) {
            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: true });
            let selector = await this.$apollo.query(ALL_PLANS, {});
            let plans = selector('**.plans');
            let me = rootState.userInfo.plan;
            let filtered = _.filter(plans, x => x.type === me.type);
            let check = (
                _.findIndex(filtered, x => x.level > me.level) !== -1
                ||
                me.default === true
            );
            
            if (check) {
                me.upgrade = true;
            }

            commit('SET_VARIABLES_PLAN', me);
            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: false });
        },

        async CALC_CRAS({ commit, rootState, state }) {
            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: true });
            let tags = [];
            let pselector = await this.$apollo.query(
                MY_PRODUCTS,
                {
                    limit: 3,
                    filters: {
                        active: true
                    }
                }
            );

            let iselector = await this.$apollo.query(
                MY_INQUIRIES,
                {
                    limit: 3,
                    filters: {
                        active: true
                    }
                }
            );

            let industries = [];

            // tags = _.concat(tags, _.map(pselector('**.list.list'), x => _.find(x.contents, i => i.default).name));
            tags = _.concat(tags, _.map(pselector('**.list.list'), x => x.industry.name));
            industries = _.concat(industries, _.map(pselector('**.list.list'), x => x.industry.name));
            // tags = _.concat(tags, _.map(iselector('**.list.list'), x => _.find(x.contents, i => i.default).name));
            tags = _.concat(tags, _.map(iselector('**.list.list'), x => x.industry.name));
            industries = _.concat(industries, _.map(iselector('**.list.list'), x => x.industry.name));

            tags.push(rootState.userInfo.industry.name);
            industries.push(rootState.userInfo.industry.name);

            // tags.push(rootState.userInfo.title.name);
            
            // if (rootState.userInfo.position) {
            //     tags.push(rootState.userInfo.position.name);
            // }

            tags = _.concat(tags, _.map(_.get(_.find(rootState.userInfo.tags, { default: true }), 'tags', []), x => x.tag));

            let craselector = await this.$apollo.query(COM_RATE_SUGGESTIONS, { stags: industries, ctags: tags });
            let suggestions = craselector('**.suggestions');
            let filteredSuggestions = _.filter(suggestions, s => _.findIndex(state.variables.tags, t => t === s) === -1);

            commit(
                'SET_VARIABLES_CRAS',
                {
                    compatibility: craselector('**.compatibility'),
                    suggestions: filteredSuggestions
                }
            );

            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: false });
        },

        async FETCH_MATCHINGS({ commit }) {
            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: true });
            let sselector = await this.$apollo.query(MATCHINGS, { type: 'SELLER', limit: 2, cursor: null });
            let bselector = await this.$apollo.query(MATCHINGS, { type: 'BUYER', limit: 2, cursor: null });
            let slist = sselector('**.list');
            let blist = bselector('**.list');
            let matchings = _.map(
                _.uniqBy(
                    _.concat(slist, blist), 'id'),
                m => (
                    _.merge(m, { name_surname: shorter(m.name, m.surname) })
                )
            );
            commit('SET_VARIABLES_MATCHINGS', matchings);
            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: false });
        },

        async FETCH_CHART_DATA({ commit }, { year }) {
            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: true });
            let bselector = await this.$apollo.query(CHART_OF_YEAR, { type: 'BUYER', year });
            let sselector = await this.$apollo.query(CHART_OF_YEAR, { type: 'SELLER', year });
            commit(
                'SET_VARIABLES_CHART_OF_YEAR',
                _.concat(
                    _.map(sselector('**.chartOfYear'), x => _.set(x, 'type', 'SELLER')),
                    _.map(bselector('**.chartOfYear'), x => _.set(x, 'type', 'BUYER'))
                )
            );
            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: false });
        },

        async LOAD_SOCIAL_ACCOUNTS({ commit }) {
            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: true });
            let now = moment().format("YYYY-MM-DD HH:mm:ss");
            let filters = {
                expired_at: {
                    gt: now,
                },
                type: {
                    in: [
                        'FACEBOOK',
                        'TWITTER',
                        'LINKEDIN'
                    ]
                }
            };
            
            let selector = await this.$apollo.query(SOCIAL_ACCOUNTS, { filters });
            commit('SET_SOCIAL_ACCOUNTS', selector('**.accountList.list'));
            commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: false });
        },

        async FOLLOW({ commit }, uid) {
            try {
                commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: true });
                await this.$apollo.mutate(FOLLOW, { uid });
                commit('REMOVE_VARIABLES_MATCHINGS', uid);
            } catch (error) {
                throw error;
            } finally {
                commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: false });
            }
        },

        async GET_TAGS({ commit }) {
            try {
                commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: true });
                let selector = await this.$apollo.query(GET_TAGS, {});
                let tags = selector('**.tags.get.tags');
                commit(
                    'SET_VARIABLES_TAGS',
                    _.flatMapDeep(
                        _.map(
                            _.filter(
                                tags,
                                t => (
                                    t.default === true
                                )
                            ),
                            t => (
                                _.map(
                                    t.tags,
                                    x => x.tag
                                )
                            )
                        )
                    )
                );
            } catch (error) {
                throw error;
            } finally {
                commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: false });
            }
        },

        async ADD_TAG({ commit, state, dispatch }, tag) {
            try {
                commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: true });
                await this.$apollo.mutate(SET_TAGS, { tags: _.uniq(_.concat(state.variables.tags, [tag])) });
                await dispatch('GET_TAGS');
                await dispatch('CALC_CRAS');
            } catch (error) {
                throw error;
            } finally {
                commit('SET_VARIABLES_LOADINGS', { type: 'process', loading: false });
            }
        }
    }
};

export default landing;